<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="openAddPatientModal"
      v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Clients
    </button>

    <modal
      class="add-patient-modal"
      name="add-patient-modal"
      transition="pop-out"
      :height="600"
      :width="1200"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeAddPatientModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="m-3 modal-body-container">
          <ListPatients
            v-bind:hideDetails="true"
            :connect="
              (patient) => connect({ providerId: provider.id, patient })
            "
          ></ListPatients>
        </div>
      </div>
    </modal>

    <div
      class="
        card
        shadow-card
        theme-card
        auto-overflow-x
        mobile-tablet-no-card
        border border-lg-0
      "
    >
      <alert class="m-0" v-if="patients.isLoading" />
      <alert
        class="m-0"
        :hideLoader="true"
        v-if="!patients.isLoading && patients.data && patients.data.length == 0"
        >No Clients Attached</alert
      >
      <table
        class="
          table
          overflow-auto
          table-striped
          mb-0
          responsive-mobile-tablet-table
        "
        v-if="!patients.isLoading && patients.data && patients.data.length > 0"
      >
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Referred By</th>
            <th>Diagnosis</th>
            <th>Date Of Injury</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in patients.data" :key="p.id">
            <td><b class="mobile-tablet-only mr-2">ID:</b>{{ p.user_id }}</td>
            <td>
              <div
                class="d-inline-flex align-items-center justify-content-center"
              >
                <b class="mobile-tablet-only mr-2">Name:</b>
                <img
                  class="user-avatar-img mr-2 mr-lg-3 rounded-circle"
                  :src="p.profile_picture || profileImagePlaceholder"
                />
                <router-link :to="`/clients/${p.id}`"
                  >{{ p.last }}, {{ p.first }}
                </router-link>
              </div>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Referred By:</b>
              <div class="referral-container">
                <div
                  v-for="referral in p.referredBy"
                  :key="referral.id"
                  class="referral-name"
                >
                  {{ referral.name }}
                </div>
              </div>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Diagnosis:</b>{{ p.diagnosis }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Date Of Injury:</b
              >{{ formattedDate(p.injured_at) }}
            </td>
            <td class="text-center td-actions">
              <router-link :to="`/clients/${p.id}`" class="link-black">
                <b class="mobile-tablet-only mr-2">Edit</b
                ><i class="fa fa-user actionIcons"></i>
              </router-link>
              <span
                class="ml-2 text-danger pointer-cursor"
                v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
                ><b
                  class="mobile-tablet-only mr-2"
                  @click="!p.disconnecting && !disconnectPatient(p)"
                  >Disconnect</b
                >
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="p.disconnecting"
                ></span>
                <i
                  class="fas fa-unlink"
                  @click="disconnectPatient(p)"
                  v-else
                ></i
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { default as ListPatients } from "../../../patients/ListPatients";
import * as dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "providers.patients",
  data() {
    return {
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  computed: {
    ...mapState({
      provider: (state) => state.providers.provider,
      patients: (state) => state.providers.patients,
    }),
  },
  mounted() {
    this.get(this.provider.id);
  },
  methods: {
    ...mapActions({
      get: "providers/patients/get",
      connect: "providers/patients/connect",
      disconnect: "providers/patients/disconnect",
    }),
    openAddPatientModal() {
      this.$modal.show("add-patient-modal");
    },
    closeAddPatientModal() {
      this.$modal.hide("add-patient-modal");
    },
    formatHealthCard(healthCard) {
      if (healthCard && healthCard.startsWith("ey")) {
        return window.decrypt(healthCard).replace(/[^0-9]\s+/gi, "");
      }

      return healthCard;
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
    disconnectPatient: function (patient) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You are going to disconnect this client",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, disconnect",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const data = {
              providerId: this.provider.id,
              patientId: patient.id,
            };
            patient.disconnecting = true;
            this.$forceUpdate();
            this.disconnect(data).then((disconnected) => {
              patient.disconnecting = false;
              this.$forceUpdate();
              if (disconnected) {
                this.get(this.provider.id);
              }
            });
          }
        });
    },
  },
  components: {
    ListPatients,
  },
};
</script>
<style scoped lang="scss">
.auto-overflow-x {
  overflow-x: auto;
}

.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.referral-container {
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: inline-grid;
  @media (max-width: 1024px) {
    display: inline-block;
  }

  .referral-name {
    background-color: #222222;
    color: #fff;
    border-radius: 8px;
    display: inline-block;
    padding: 3px 12px;
    margin: 3px;
    font-size: 0.85em;
  }
}
</style>

<style lang="scss">
.add-patient-modal {
  .shadow-card {
    box-shadow: none;
  }
}
</style>
